<template>
  <div>
    <div class="log-success" v-if="paper.score >= 116">
      <p class="count">{{ index }}차</p>
      <p class="core">{{ paper.score }}점</p>
      <strong>합격</strong>
    </div>
    <div class="log-fail" v-else>
      <p class="count">{{ index }}차</p>
      <p class="core">{{ paper.score }}점</p>
      <strong>불합격</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: "StandardComponent",
  props: {
    paper: Object,
    index: Number,
  },
  data() {
    return {
      resultText: '',
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>
.form-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px 18px;
}

.log-box {
  width: 100%;
  box-sizing: border-box;
  background: #F8F8FA;
  border-radius: 8px;
  padding: 27px 18px;
}

.log-box p {
  margin-right: 10px;
}

.bs-flex {
  display: flex;
  align-items: center;
}

.bs-flex h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 0 10px 0 0;
  width: 150px;
}

.log-success, .log-fail {
  width: 300px;
  display: flex;
  align-items: baseline;
  background: #fff;
  padding: 5px 10px;
  margin-bottom: 8px;
}

.log-success strong {
  color: #0069CA;
}

.log-fail strong {
  color: #E14F4E;
}

.log-success:last-child, .log-fail:last-child {
  margin-bottom: 0;
}

</style>
