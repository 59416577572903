<template>
  <div class="form-container">
    <div class="bs-flex">
      <h5>평가 이력</h5>
      <div class="log-box">
        <StandardComponent :paper="standardInfo.papers[i]" v-for="(a,i) in standardInfo.papers" :key="i" :index="i + 1"/>
      </div>
    </div>

  </div>
</template>

<script>
import StandardComponent from "@/views/master/class/components/StandardComponent";

export default {
  name: "StandardizationForm",
  components: {StandardComponent},
  props: {
    standardInfo: Object,
  },
  data() {
    return {
      isNullData: false,
    }
  },
  mounted() {
  },
  methods: {}
}
</script>

<style scoped>
.form-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 27px 18px;
}

.log-box {
  width: 100%;
  box-sizing: border-box;
  background: #F8F8FA;
  border-radius: 8px;
  padding: 27px 18px;
}

.log-box p {
  margin-right: 10px;
}

.bs-flex {
  display: flex;
  align-items: center;
}

.bs-flex h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.0015em;
  color: #272833;
  margin: 0 10px 0 0;
  width: 150px;
}

.log-success, .log-fail {
  width: 300px;
  display: flex;
  align-items: baseline;
  background: #fff;
  padding: 5px 10px;
  margin-bottom: 8px;
}

.log-success strong {
  color: #0069CA;
}

.log-fail strong {
  color: #E14F4E;
}

.log-success:last-child, .log-fail:last-child {
  margin-bottom: 0;
}

</style>
